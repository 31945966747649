import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

Vue.component('fa-icon', FontAwesomeIcon);
library.add(fas);

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            themes: {
                light: {
                    primary: colors.purple,
                    secondary: colors.grey.darken1,
                    accent: colors.shades.black,
                    error: colors.red.accent3,
                },
                dark: {
                    primary: colors.blue.lighten3,
                },
            },
        },
        icons: {
            iconfont: "mdiSvg | faSvg",
        },
    },
});
