import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "",
        name: "Dashboard",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    },
    {
        path: "/admin",
        name: "Admin",
        component: () =>
            import(/* webpackChunkName: "admin" */ "../views/Admin/Admin.vue")
    },
    {
        path: "/admin/users/:companyId",
        name: "Users",
        component: () =>
            import(/* webpackChunkName: "admin" */ "../views/Admin/Users.vue"),
        props: (route) => ({
            companyId: route.params.companyId,
        }),
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    },
    {
        path: "/reports",
        name: "Reports",
        component: () =>
            import(/* webpackChunkName: "reports" */ "../views/Reports/Reports.vue"),
    },
    {
        path: "/reports/source/:id",
        name: "HTML Editor",
        component: () => import(/* webpackChunkName: "reports" */ "../views/Reports/ReportEditor.vue"),
    },
    {
        path: "/reports/taxonomy",
        name: "Taxonomy Editor",
        component: () => import(/* webpackChunkName: "reports" */ "../views/Reports/TaxonomyEditor.vue"),
    }
];

const router = new VueRouter({
    routes,
});

/* router.addRoutes([{
    path: "/test",
    name: "Test",
    component:() => import("../Test.vue")
}]); */
export default router;
