"use strict";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import qs from "qs";

Vue.config.productionTip = false;

import vuetify from "@/plugins/vuetify";
import "./plugins/vee-validate";
import { createEnum, processAlert } from "@/plugins/utils";
import './registerServiceWorker'
/**
 *
 * @param {AxiosRequestConfig} config axios config
 * @param {string} alertType //"message" or "toast". default is toast
 * @param {any} alertTarget
 *
 * @returns {Promise}
 */
Vue.prototype.$apiHttp = function(
    config,
    alertFailTarget = null,
    alertSuccessTarget = null
) {
    config.baseURL = config.baseURL !== undefined ? config.baseURL : "/api";
    if (!config.method || config.method.length == 0) {
        config.method = "get";
    }
    /* if (config.method.toUpperCase() == "POST"){
        //bug in Chrome, post sta in pending la infinit daca serverul e picat
        config.timeout = 5000;
    } */

    if (!config.headers) {
        config.headers = {};
    }
    
    if (this.$store.state.apiToken){
        let token = this.$store.state.apiToken;
        let auth = token.token_type.toLowerCase();
        auth = auth[0].toUpperCase() + auth.slice(1);
        auth += " " + token.access_token;
        config.headers = {
            ...config.headers,
            "Authorization": auth
        };
    }
 
    config.paramsSerializer = function(params){
        return qs.stringify(params, {arrayFormat: "repeat"});
    }

    let axiosInstance = axios.create(config);

    let vm = this;
    axiosInstance.interceptors.response.use(
        (response) => {
            if (response.data.alert) {
                if (!alertSuccessTarget) {
                    vm.$store.commit("setGlobalMessage", response.data.alert);
                } else {
                    alertSuccessTarget = processAlert(response.data.alert);
                }
            }
            return response;
        },
        async (error) => {
            let convertedBlobError = null;
            if (
                error.request.responseType === 'blob' &&
                error.response.data instanceof Blob &&
                error.response.data.type &&
                error.response.data.type.toLowerCase().indexOf('json') != -1
            ) {
                convertedBlobError = JSON.parse(await error.response.data.text());
            }
            let alert = {
                severity: "error",
                message: error.message
            };
            
            if (axios.isCancel(error)){
                return Promise.reject(error);
            }

            if (error.response && error.response.status == 401) {
                if(vm.$store.state.apiToken === null || vm.$store.state.apiToken === undefined) {
                    vm.$router.push({ name: "Login"});
                    vm.$store.commit("setPageMenu", []);
                    
                } 
                if(error.response.statusText === "Unauthorized") {
                    if(error.response.data.detail === "Not enough permissions") {
                        if(this.$store.state.userRole === 1) {
                            vm.$router.push({ name: "Admin"});
                        } else {
                            vm.$router.push({ name: "Dashboard"});
                        }
                    } 
                    if(error.response.data.message === "Invalid or expired token. Please login.") {
                        vm.$router.push({ name: "Login"});
                        this.$store.commit("REMOVE_apiToken");
                        vm.$store.commit("setPageMenu", []);
                    } 
                }
            }

            if (error.response.headers['sp-client-alert']) {
                alert = convertedBlobError ?? error.response.data;
            }else{
                if (error.response && error.response.data){
                    if (error.response.status != 404 && error.response.status != 500){
                        error = error.response.data
                        if (typeof(error.detail) == "string"){
                            alert.message = error.detail;
                        }else{
                            alert.message = error.detail[0].msg
                        }
                    }
                }
            }
            
            if (!alertFailTarget) {
                vm.$store.commit("setGlobalMessage", alert);
            } else {
                Object.assign(
                    alertFailTarget,
                    processAlert(alert)
                );
            }
            return Promise.reject(error);
        }
    );
    return axiosInstance(config);
};

Vue.prototype.$enumValues = async function(enumNames){
    if (typeof enumNames === "string"){
        enumNames = [enumNames]
    }

    let enumValues = await this.$apiHttp({
        url: "/enums/values",
        params: {
            enum_names: enumNames
        }
    })

    enumValues = enumValues.data

    return Object.fromEntries(Object.entries(enumValues).map(([key, values])=>{
        return [key, createEnum(values)]
    }))
}

Vue.prototype.$log = console.log;

const requireComponent = require.context("@/components", true, /\.vue$/);

requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);

    const componentName = componentConfig.default.name;

    Vue.component(
        `Uv${componentName}`,
        componentConfig.default || componentConfig
    );
});

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
