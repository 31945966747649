import { mapMutations, mapState } from "vuex";
import _ from "lodash";
export const enumsMixin = {
    created: function(){
        let enumNames = this.enumNames
        if (typeof enumNames === "string"){
            enumNames = [enumNames]
        }
        const enumsToGet = _.difference(enumNames, Object.keys(this.enums))
        if (!enumsToGet?.length){
            return
        }
        this.$enumValues(enumsToGet).then(res => {
            this.setEnums({...this.enums, ...res})
        })
    },
    methods: {
        ...mapMutations({
            setEnums: "SET_enums"
        }),
        enumItems(enumName, enumObject, textValues=false) {
            if (!enumObject) {
                return null
            }
            const res = Object.keys(enumObject).map((key) => {
                let item = {text: key}
                item.value = textValues ? item.text : key
                return item;
            })
            /* res.push({
                value: null,
                text: this.$t('labels.undefined')
            }) */
            return res
        }
    },
    computed: {
        ...mapState({
            enums: state => state.cache.enums
        }),
        enumsItems: function(){
            return Object.fromEntries(Object.entries(this.enums).map(([name, values]) => {
                return [name, this.enumItems(name, values)];
            }))
        },
        enumsTextItems: function(){
            return Object.fromEntries(Object.entries(this.enums).map(([name, values]) => {
                return [name, this.enumItems(name, values, true)];
            }))
        }
    }
}

export const dimensionsMixin = {
    created(){
        this.getDimensionTagNameItems();
    },
    computed:{
        ...mapState("xbrlData", [
            "taxonomies",
        ]),
    },
    data(){
        return {
            dimensionNameItems: [],
            dimensionValueItems: [],
            dimensionsDict: {}
        }
    },
    methods:{
        getDimensionTagNameItems() {
            if(this.taxonomies.pre) {
                for(let node of this.taxonomies.pre) {
                    let dimensionNodes = this.getNodeDimensionItems(node);
                    if(dimensionNodes.length > 0) {
                        this.addHeaderItem(this.dimensionNameItems,node);
                        for(let dimensionNode of dimensionNodes) {
                            let concept = this.taxonomies.concepts[dimensionNode.id];
                            let dimension_leafs = this.getNodeDomainMemberItems(dimensionNode)
                            this.dimensionsDict[concept.xId] = dimension_leafs.map((leaf) => ({ text: this.taxonomies.concepts[leaf.id].label, value: this.taxonomies.concepts[leaf.id].xId}));
                            this.addChildItemsIntoList(this.dimensionNameItems,concept);
                        }
                        this.addChildItemsIntoList(this.dimensionNameItems,{label: 'Total', xId: 'total'});
                        this.addDividerIntoList(this.dimensionNameItems);
                    }
                }
            }
        },
        getAllChildrenNodes(node) {
            if (node == undefined || node == null) {
                return []
            }
            let nodesList = [node];
            if ( node.concepts && node.concepts.length > 0) {
                for (let child of node.concepts){
                    nodesList.push(...this.getAllChildrenNodes(child))
                }
            }
            return nodesList;
        },
        getNodeDimensionItems(node) {
            let childNodes = this.getAllChildrenNodes(node);
            let list = [];
            for(let child of childNodes) {
                let concept = this.taxonomies.concepts[child.id];
                if(concept?.substitutionGroup === "xbrldt:dimensionItem") {
                    list.push(child);
                }
            }
            return list;
        },
        addHeaderItem(targetArray,item) {
            targetArray.push({header: item.label});
        },
        getNodeDomainMemberItems(node) {
            let children = this.getAllChildrenNodes(node);
            children = children.filter((node) =>  {
                const concept = this.taxonomies.concepts[node.id]
                return concept?.type?.includes(":domainItemType") ?? false;
            });
            return children;
        },
        addChildItemsIntoList(targetArray,item) {
            targetArray.push({text: item.label, value: item.xId});
        },
        addDividerIntoList(targetArray) {
            targetArray.push({divider: true});
        },
    }
}