import Vue from "vue";
import { extend, ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { email, numeric, regex, required, digits, max, min_value, min, alpha_dash } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import ro from "vee-validate/dist/locale/ro.json";
extend("email", { ...email});
extend("numeric", { ...numeric});
extend("required", {...required});
extend("regex", { ...regex });
extend("digits", { ...digits });
extend("max", { ...max });
extend("min", { ...min });
extend("min_value", { ...min_value });
extend('alpha_dash', {...alpha_dash});
extend("phone", {
    validate(value){
        return new RegExp(/^\+?[0-9]{10,15}$/, "g").test(value);
    },
});

extend('strongpwd', {
    validate(value){
        return /^(?=.*?[!@#$%^&()*\-_]+)(?=.*?[A-Za-z0-9]+)[A-Za-z0-9!@#$%^&()*\-_]{8,}$/.test(value);
    }
});

extend('matches', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
});

extend('checked', {
    validate(value) {
        return value == true;
    },
    message: "Required",
});

extend("ccnumber", {
    validate(value){
        if (/[^0-9-\s]+/.test(value)) return false;
        // The Luhn Algorithm. It's so pretty.
        let nCheck = 0, bEven = false;
        value = value.replace(/\D/g, "");

        for (var n = value.length - 1; n >= 0; n--) {
            var cDigit = value.charAt(n),
                nDigit = parseInt(cDigit, 10);

            if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

            nCheck += nDigit;
            bEven = !bEven;
        }

        return (nCheck % 10) == 0;
    },
});

extend("isurl", {
    validate(value){
        //return value.toLower().startsWith("http(s)?://");
        return /^www\./.test(value);
    },
    message: "Invalid URL",
});

extend('noundescore', {
    validate(value) {
        return !value.includes("_");
    },
});

localize({
    en: {
        messages: {
            ...en.messages,
            required: "Required",
            checked: "Required",
            numeric: "Must be a number",
            email: "Invalid email address",
            matches: 'The value must match "{target}"\'s value',
            phone: "Invalid phone number",
            digits: "Must be a number with exactly {length} digits",
            ccnumber: "Invalid credit card number",
            max: "Must have max {length} characters",
            min_value:"Cannot be less than {min}",
            noundescore:"Field cannot contain undescore"
        }
    }
});
localize({
    ro: {
        messages: {
            ...ro.messages,
            required: "Obligatoriu",
            checked: "Obligatoriu",
            numeric: "Trebuie să fie un număr",
            email: "Adresă email invalidă",
            matches: 'Valoarea trebuie sa fie identică cu valoarea câmpului "{target}"',
            phone: "Număr de telefon invalid",
            digits: "Trebuie să fie un număr cu exact {length} cifre",
            ccnumber: "Număr card invalid",
            max: "Trebuie sa contina maxim {length} caractere",
            min_value: "Nu trebuie să fie mai mic de {min}",
            noundescore:"Câmpul nu trebuie să conțină sublinie(underscore)"
        }
    }
});

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);
